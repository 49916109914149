import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/shop-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/shops', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/processing/shops/${queryParams.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/processing/shops', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendApiCredentialsByEmail(ctx, shopId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/processing/shops/${shopId}/api-credentials/send-by-email`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateApiCredentials(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/processing/shops/generate-api-credentials')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
